import React, { useState } from 'react'
import Topbar from '../landing/Topbar'
import Navbar from '../../components/Navbar'
import Footer from '../../Footer'
import Breadcrumbs from '../../components/Breadcrumbs'
import axios from 'axios'
import Spinner from '../facultydashboard/formGroup/Spinner'
import { Link } from 'react-router-dom'
function Msc() {
    const semster=[
        {
        sem:"Semester 1",
        lnk:"https://drive.google.com/file/d/1DPr1uy-utrctpFkLU84O6yMrUNjoQye1/view"
    },
    {
        sem:"Semester 2",
        lnk:"https://drive.google.com/file/d/1DPr1uy-utrctpFkLU84O6yMrUNjoQye1/view"
    },     {
        sem:"Semester 3",
        lnk:"https://drive.google.com/file/d/1DPr1uy-utrctpFkLU84O6yMrUNjoQye1/view"
    },     {
        sem:"Semester 4",
        lnk:"https://drive.google.com/file/d/1DPr1uy-utrctpFkLU84O6yMrUNjoQye1/view"
    },
]
const [datas, setData] = React.useState({})
const [loader,setLoader] = useState(true)
// console.log(datas)
const siteData = () => {
  axios.get('/api/v1/api/site/data')
    .then(function (response) {
      // handle success
      // console.log(response);
      setData(response.data[0])
      setLoader(false)
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
}
React.useEffect(() => {
  siteData()
}, [])
const [pg, setPg] = React.useState([])
// console.log(data)
const pgData = () => {
  axios.get('/api/v1/find-file-syllabus')
    .then(function (response) {
      setPg(response.data)
    })
    .catch(function (error) {
      console.log(error);
    })
}
React.useEffect(() => {
  pgData()
}, [])
// console.log("k",pg)
  return (
    <div>
       {
        loader && <Spinner/>
      }
<Topbar/>
<Navbar datas={datas}/>
<Breadcrumbs page={"BLIS"} pagelink="#" sublink='#' subpage={"Courses & Syllabi"}/>
{/* <div className="mx-auto py-5 px-4 w-full max-w-7xl border-b-2 border-gray-200">
      <h3 className="text-xl text-red-600 font-medium">Course Objective</h3>
      <p className="mt-3 max-w-4xl text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque alias id quis hic tempora explicabo provident, iure quae praesentium accusantium laborum ab expedita, veniam error, nemo eius! Officiis, cum reiciendis.</p>
    </div> */}
    <div className='h-[30rem]  mt-4  px-4 mx-auto'>
            <>

<div class="overflow-x-auto   px-4">
  {/* <table class="w-[30rem] border  divide-y-2 divide-gray-200 text-sm">
    <thead>

    </thead>
    <tbody class="divide-y border  divide-gray-200">
    <tr>
                <td class="whitespace-nowrap border  px-4 py-2 font-medium text-gray-900">
                Course Structure and Full Syllabus
                </td>
                <td class="whitespace-nowrap border  px-4 py-2">
                  <a
                    href={semster.lnk} target="_blank"
                    class="inline-block  rounded bg-red-600 px-4 py-2 text-xs font-medium text-white hover:bg-red-700"
                    >
                    View
                  </a>
                </td>
              </tr>
      </tbody>
</table> */}
</div>
</>
<div className='h-[30rem] flex mt-4  '>
            <>

<div class="overflow-x-auto   px-4">
  <table class="w-[30rem] border  divide-y-2 divide-gray-200 text-sm">
    <thead>
      <tr>
        <th
          class="whitespace-nowrap px-4 border  py-2 text-left font-medium text-gray-900"
          >
          BLIS Syllabus
        </th>

        <th class="px-4 border  py-2"></th>
      </tr>
    </thead>

    <tbody class="divide-y border  divide-gray-200">

        {pg.map((a)=>{

            return(
               <>
               {
                 a.publish && a.class === 'PG'  &&   <tr>
                <td class="whitespace-nowrap border  px-4 py-2 font-medium text-gray-900">
                  {a.title}
                </td>
                <td class="whitespace-nowrap border  px-4 py-2">
                  <Link
                    to={`/api/v1/download-syllabus/${a.fileName}`} target="_blank"
                    class="inline-block  rounded bg-red-600 px-4 py-2 text-xs font-medium text-white hover:bg-red-700"
                    >
                    View
                  </Link>
                </td>
              </tr>
               }

               </>
            )
        })}

    </tbody>
  </table>
</div>
                      </>
                      </div>
                      </div>
<Footer datas={datas}/>
    </div>


  )
}

export default Msc
